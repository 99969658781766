@use '@hellodarwin/core/scss/_variables' as variables;
@use '@hellodarwin/core/scss/antd-common' as *;

@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Figtree:ital,wght@0,500;0,600;0,700;1,500;1,600;1,700&display=swap');
@import url('https://use.typekit.net/lht6zme.css');

html {
  scroll-behavior: smooth;
}

body {
  scroll-behavior: smooth;
  overflow-x: visible;
  overflow-y: visible;
  background-color: variables.$hd-white;
  font-family: 'Inter', sans-serif;
  margin: 0;
  * {
    box-sizing: border-box;
  }
}
a {
  color: variables.$hd-purple-2;

  &:hover {
    color: variables.$hd-purple-2;
  }
  & > * {
    color: inherit;
    &:hover {
      color: inherit;
    }
  }
}

/*Common CSS*/

.ant-upload {
  background-color: #eff0f6 !important;
  border-radius: variables.$border-radius;
}

.ant-menu {
  background: transparent !important;
  border: none !important;
}

.ant-tag {
  border-radius: 99px;
  border: none;
  width: fit-content;
}

.ant-radio-checked .ant-radio-inner {
  border-color: #89178f !important;
  background-color: #89178f !important;
}
